@font-face {
  font-family: 'thesimssansboldsc';
  src: url(./fonts/simlish.otf);
}

.App {
  text-align: center;
}

h1 {
  font-family: 'thesimssansboldsc';
}

p {
  padding: 0 3em;
}

.ant-space {
  margin: 0 1em;
}

.ant-btn-secondary {
  color: white !important;
}

.ant-input-number-group-addon {
  color: white !important;
}

.ant-btn-link {
  color: #a6e329;
  margin-bottom: 3em;
}

.App-header {
  text-align: left;
  background-color: #4276b7;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

